<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000270')" prop="types">
              <Select transfer v-model="pageParams.types" filterable multiple>
                <Option v-for="(item, index) in exportTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000271')" prop="operateCode">
              <Input v-model.trim="pageParams.operateCode" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000272')" class="date_picker_style">
              <Date-picker
                type="datetimerange"
                @on-clear="resetDate"
                @on-change="getDateValue"
                :clearable="true"
                transfer
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="registerTime"></Date-picker>
            </Form-item>
          </Col>
          <Col style="width: 170px;" push="1">
            <Button @click="reset" class="mr12">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search">{{ $t('key1000274') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <local-tabButton :tabButtonList="exportStatusList" @selectStatus="exportStatusBtn"></local-tabButton>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table highlight-row
        border
        max-height="550"
        :loading="tableLoading"
        :columns="tableColumns"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {downloadTemplateFile} from "@/utils/common";

export default {
  name: "publicExport",
  mixins: [Mixin],
  props: {
    exportTypeList: { // 导出类型
      type: Array,
      defautl: () => []
    }
  },
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      registerTime: [],
      pageParams: {
        createdTimeStart: null,
        createdTimeEnd: null,
        types: [],
        operateCode: '',
        status: null,
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      getProgressTimer: null,
      tableColumns: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000275'),
          width: 70,
          align: 'left',
          type: 'index',
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000276'),
          key: 'operateCode',
          minWidth: 130,
          align: 'left'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000277'),
          key: 'types',
          align: 'left',
          minWidth: 130,
          render: (h, params) => {
            let type = params.row.type;
            let text = '';
            v.exportTypeList.map((item) => {
              if (item.value === type) {
                text = item.name;
              }
            });
            return h('span', text);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000278'),
          key: 'createdTime',
          align: 'left',
          minWidth: 180,
          sortable: true,
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.createdTime, 'fulltime'));
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000279'),
          key: 'status',
          align: 'left',
          minWidth: 130,
          render: (h, params) => {
            let status = params.row.status;
            if (status === 2) {
              let currentTaskCount = params.row.currentTaskCount || 0;
              let totalTaskCount = params.row.totalTaskCount || 1;
              return h('div', [
                h('p', {style: {color: '#999'}}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000280')),
                h('Progress', {
                  props: {
                    percent: Math.ceil((currentTaskCount / totalTaskCount) * 100),
                    status: 'active'
                  }
                })
              ]);
            }
            return h('Badge', {
              props: {
                status: v.exportStatusList.filter((item) => {
                  return item.value === status;
                })[0].type,
                text: v.exportStatusList.filter((item) => {
                  return item.value === status;
                })[0].title || '--'
              }
            });
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000222'),
          key: 'createdBy',
          align: 'left',
          minWidth: 130,
          render: (h, params) => {
            let userName = '';
            if (v.userInfoList !== null && v.userInfoList[params.row.updatedBy] !== undefined) {
              userName = v.userInfoList[params.row.updatedBy].userName;
            } else if (params.row.createdBy === aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000281')) {
              userName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000281');
            } else {
              userName = '';
            }
            return h('div', userName);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000282'),
          align: 'left',
          key: 'reason',
          width: 300,
          render: (h, params) => {
            return v.checkContentOverflow(h, params.row.reason, 300, 2, '#FF0000')
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
          width: 120,
          align: 'left',
          renderHeader(h, params) {
            return h('div', {class: 'flex align-items-center'}, [
              h('Tooltip', {
                props: {
                  offset: 13,
                  transfer: true,
                  maxWidth: 150,
                  content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000284'),
                  placement: 'top-end'
                }
              }, [
                h('Icon', {
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                  style: {
                    cursor: 'pointer',
                    marginRight: '5px'
                  }
                })
              ]),
              h('span', aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'))
            ]);
          },
          render: (h, params) => {
            // 状态为 导出完成 的才能下载
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000285'),
                    show: params.row.status !== 2 && params.row.status !== 4 && params.row.targetPath,
                    clickFn: () => {
                      downloadTemplateFile(params.row.targetPath);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      exportStatusList: [
        {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
        {selected: false, value: 2, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000286'), type: 'warning'},
        {selected: false, value: 3, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000287'), type: 'success'},
        {selected: false, value: 4, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000288'), type: 'error'}
      ],
      restartTalg: false,
    };
  },
  created() {
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.restartTalg = true;
      if (v.$route.query && v.$route.query.operateCode) {
        v.pageParams.operateCode = v.$route.query.operateCode;
      }
      (async () => {
        await v.getUserName();
      })();
      v.search();
    },
    // 重启下载任务
    restartDownloadTask() {
      let v = this;
      v.axios.get(api.get_restartExport).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
        }
      });
    },
    // 判断当前数据中是否有导出中的任务，如果有开启导出中的任务轮询
    exportingTasks(data) {
      let v = this;
      if (data.length > 0) {
        let talg = data.some(i => i.status === 2);
        if (talg) {
          if (v.restartTalg) {
            v.restartDownloadTask();
          }
          v.getProgressTimer = setTimeout(() => {
            v.restartTalg = false;
            v.getList(false);
          }, 5000);
        } else {
          v.clearTheTimer();
        }
      }
    },
    // 查询按钮
    search() {
      this.tableData = [];
      this.pageParams.pageNum = 1;
      this.getList();
    },
    // 重置按钮
    reset() {
      let v = this;
      v.exportStatusBtn(0, null);
      v.$refs['pageParams'].resetFields();
      v.resetDate();
      v.pageParams.operateCode = '';
      v.pageParams.types = [];
      v.registerTime = [];
      v.search();
    },
    // 列表查询的方法
    getList(value = true) {
      let v = this;
      v.total = 0;
      if (v.getPermission('erpCommon_queryTask')) {
        let types = [];
        if (!v.pageParams.types.length) {
          v.exportTypeList.map((item) => {
            types.push(item.value);
          });
        } else {
          types = v.pageParams.types;
        }
        let obj = Object.assign({}, v.pageParams);
        obj.types = types;
        v.tableLoading = value;
        v.axios.post(api.post_erpCommon_queryTask, obj).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas
            v.tableData = data.list || [];
            v.total = data.total;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
            v.exportingTasks(v.tableData);
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 重置创建时间
    resetDate() {
      this.pageParams.createdTimeStart = null;
      this.pageParams.createdTimeEnd = null;
    },
    // 获取日期返回值
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000289'));
        v.resetDate();
      } else {
        if (!value[0]) {
          v.pageParams.createdTimeStart = null;
          v.pageParams.createdTimeEnd = null;
        } else {
          let timeList = v.defaultTimePeriod(value);
          v.registerTime = v.defaultTimePeriod(value, false);
          v.pageParams.createdTimeStart = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.createdTimeEnd = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 选取导出状态
    exportStatusBtn(index, value) {
      let v = this;
      v.pageParams.status = value;
      v.exportStatusList.forEach((n, i) => {
        n.selected = i === index;
      });
      v.tableData = [];
      v.pageParamsStatus = true;
    },
    // 清空定时器
    clearTheTimer() {
      clearTimeout(this.getProgressTimer);
      this.getProgressTimer = null;
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
  // 离开页面时，清空定时器，暂停轮询查询下载任务的进度
  beforeDestroy() {
    this.clearTheTimer();
  }
};
</script>

<style scoped>

</style>
