<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000726')},
        {value: 'inventoryExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000760')},
        {value: 'inventoryLogExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005850')},
        {value: 'packageExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002598')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005849')}`},
        {value: 'supplierBillExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005851')},
        {value: 'wmsPickupOrderExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005852')},
        {value: 'packageInfoOrderExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001449')},
        {value: 'packageInfoStockExport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001342')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
